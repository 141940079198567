<template>
    <div class="pathology-form" v-if="doctorsList.length > 0 && pathologyList.length > 0 && centersList.length > 0 ">
        <div class="pathologie-sep">
            <h3><span v-if="this.pathologyUpdate">[{{this.pathologyUpdate.id }}] </span>{{ $t('admin.form.pathologyTitle')}} </h3>
            <div class="pathologie-line"></div>
        </div>   
        <div class="row">
            <div class="col50">
                <ValidationProvider  :class="['input-default', { 'disabled' : this.pathologyUpdate }]"  rules="required|max:50" v-slot="{ errors }">
                    <label class="label-pathology">{{ $t('admin.form.doctor')}}</label>
                    <select
                        v-model="doctor"
                        :class="{ 'input-error' : errors[0]}"
                        @change="$emit('changeDoctor', doctor )
                    ">
                            <option disabled class="disabled" value="-1">{{ $t('admin.form.doctor')}}</option>
                            <option v-for="(item, idx) in doctorsList" :key="idx" :value="item.id">{{`${item.firstname} ${item.lastname}` }}</option>
                    </select>
                    <span class="notice-error">{{ errors[0] }}</span>
                </ValidationProvider>
            </div>
            <div class="col50">
                <ValidationProvider  :class="['input-default', { 'disabled' : this.pathologyUpdate }]"  rules="required" v-slot="{ errors }">
                    <label class="label-pathology">{{ $t('admin.form.center')}}</label>
                    <select
                        v-model="center"
                        :class="{ 'input-error' : errors[0]}"
                        @change="$emit('changeCenter', center )
                    ">
                        <option disabled class="disabled" value="-1">{{ $t('admin.form.center')}}</option>
                        <option v-for="(item, idx) in centersList" :key="idx" :value="item.centers_id">{{ item.centers_name }}</option>
                    </select>   
                    <span class="notice-error">{{ errors[0] }}</span>
                </ValidationProvider>
            </div>
        </div>
        <div class="row">
            <div class="col50">
                <ValidationProvider  :class="['input-default', { 'disabled' : this.pathologyUpdate }]"  rules="required" v-slot="{ errors }">
                    <label class="label-pathology">{{ $t('admin.form.pathology')}}</label>
                    <select
                        v-model="pathologyId"
                        :class="{ 'input-error' : errors[0]}"
                        @change="changePathology()
                    ">
                        <option disabled class="disabled" value="-1">{{ $t('admin.form.pathology')}}</option>
                        <option v-for="(item, idx) in pathologyList" :key="idx" :value="item.pathology_id">{{ JSON.parse(item.pathology_name)[currentLang] }}</option>
                    </select>   
                    <span class="notice-error">{{ errors[0] }}</span>
                </ValidationProvider>
            </div>
            <!-----------------------------------------------------------
            AFFICHAGE CONDITIONNEL SELON TYPE DE PATHOLOGIE
            ------------------------------------------------------------->
            <template v-if="pathologyId === 1">
                <div class="col50">
                    <ValidationProvider  :class="['input-default']"  rules="required" v-slot="{ errors }">
                        <label class="label-pathology">{{ $t('admin.form.area.arthrose')}}*</label>
                        <select
                            v-model="area"
                            :class="{ 'input-error' : errors[0]}"
                                @change="changeArea()"
                        >
                            <option disabled class="disabled" value="-1">{{ $t('admin.form.area.arthrose')}}</option>
                            <option value="1">{{ $t('admin.form.area.left')}}</option>
                            <option value="2">{{ $t('admin.form.area.right')}}</option>
                            <option value="3">{{ $t('admin.form.area.both')}}</option>
                        </select>
                        <span class="notice-error">{{ errors[0] }}</span>
                    </ValidationProvider>
                </div>
            </template>
            <template v-if="pathologyId === 2">
                <div class="col50">
                    <ValidationProvider  :class="['input-default']"  rules="required" v-slot="{ errors }">
                        <label class="label-pathology">{{ $t('admin.form.area.epicondylite')}}*</label>
                        <select
                            v-model="area"
                            :class="{ 'input-error' : errors[0]}"
                                @change="changeArea()"
                        >
                            <option disabled class="disabled" value="-1">{{ $t('admin.form.area.epicondylite')}}</option>
                            <option value="1">{{ $t('admin.form.area.left')}}</option>
                            <option value="2">{{ $t('admin.form.area.right')}}</option>
                            <option value="3">{{ $t('admin.form.area.both')}}</option>
                        </select>
                        <span class="notice-error">{{ errors[0] }}</span>
                    </ValidationProvider>
                </div>
            </template>
            <template v-if="pathologyId === 3">
                <div class="col50">
                    <ValidationProvider  :class="['input-default']"  rules="required" v-slot="{ errors }">
                        <label class="label-pathology">{{ $t('admin.form.area.tendinite')}}*</label>
                        <select
                            v-model="area"
                            :class="{ 'input-error' : errors[0]}"
                                @change="changeArea()"
                        >
                            <option disabled class="disabled" value="-1">{{ $t('admin.form.area.tendinite')}}</option>
                            <option value="1">{{ $t('admin.form.area.left')}}</option>
                            <option value="2">{{ $t('admin.form.area.right')}}</option>
                            <option value="3">{{ $t('admin.form.area.both')}}</option>
                        </select>
                        <span class="notice-error">{{ errors[0] }}</span>
                    </ValidationProvider>
                </div>
            </template>
        <!-----------------------------------------------------------
        FIN
        ------------------------------------------------------------->
        </div>
        <div class="row">
            <div class="col50" v-if="pathologyId === 1 && (area == 1 || area == 3)">
                <label class="label-pathology">{{ $t('admin.form.stepLeft')}}</label>
                <ValidationProvider  :class="['input-default']"  rules="integer|between:1,4" v-slot="{ errors }" >
                    <input type="number"
                        max="4"
                        min="1"
                        v-model="stepLeft"
                        :class="{ 'input-error' : errors[0]}" 
                        :placeholder="$t('admin.form.stepLeft')"
                        @change="updateStade()"
                    />
                    <span class="notice-error">{{ errors[0] }}</span>
                </ValidationProvider>
            </div>
            <div class="col50" v-if="pathologyId === 1 && (area == 2 || area == 3)">
                <label class="label-pathology">{{ $t('admin.form.stepRight')}}</label>
                <ValidationProvider  :class="['input-default']"  rules="integer|between:1,4" v-slot="{ errors }" >
                    <input type="number"
                        max="4"
                        min="1"
                        v-model="stepRight"
                        :class="{ 'input-error' : errors[0]}" 
                        :placeholder="$t('admin.form.stepRight')"
                        @change="updateStade()"
                    />
                    <span class="notice-error">{{ errors[0] }}</span>
                </ValidationProvider>
            </div>
 
        </div>
        <div class="row">
            <div class="col50">
                <ValidationProvider  :class="['input-default']"  rules="required|between:1,499" v-slot="{ errors }" >
                    <label class="label-pathology">{{ $t('admin.form.height')}}</label>
                    <input type="number"
                        v-model="size"
                        :placeholder="$t('admin.form.height')"
                        :class="{ 'input-error' : errors[0]}"
                         @keyup="changeWeightOrSize()"
                     />
                    <span class="notice-error">{{ errors[0] }}</span>
                </ValidationProvider>
            </div>
            <div class="col50">
                <ValidationProvider  :class="['input-default']"  rules="required|between:1,499" v-slot="{ errors }">
                    <label class="label-pathology">{{ $t('admin.form.weight')}}</label>
                    <input type="number"
                        v-model="weight"
                        :placeholder="$t('admin.form.weight')"
                        :class="{ 'input-error' : errors[0]}"
                         @keyup="changeWeightOrSize()"    
                     />
                    <span class="notice-error">{{ errors[0] }}</span>
                </ValidationProvider>
            </div>
        </div>
        <div class="row">
            <div class="col100">
                <ValidationProvider  :class="['input-default', { 'disabled' : this.pathologyUpdate }]"   v-slot="{ errors }">
                    <label class="label-pathology">{{ $t('admin.form.imc')}}*</label>
                    <input type="number"
                        step="0.1"
                        v-model="imc"
                        :placeholder="$t('admin.form.imc')"
                        class="{ 'input-error' : errors[0]}" 
                    />
                    <span class="notice-error">{{ errors[0] }}</span>
                </ValidationProvider>
            </div>
        </div>
        <div class="row">
            <div class="col100">
                <template v-if="!pathologyUpdate">
                    <ValidationProvider  class="input-default"   v-slot="{ errors }">
                        <div class="ckeckbox-container">
                            <input type="checkbox"
                                v-model="notif"
                                :placeholder="$t('admin.form.notif')"
                                class="{ 'input-error' : errors[0]}"                           
                                @change="$emit('changeNotif', notif )"
                            />
                            <label>{{ $t('admin.form.notif') }}</label>
                        </div>
                        <span class="notice-error">{{ errors[0] }}</span>
                    </ValidationProvider>
                </template>
                <template v-else>
                        <div class="input-default">
                            <div class="ckeckbox-container">
                                <input type="checkbox"
                                    v-model="notif"
                                    :placeholder="$t('admin.form.notif')"
                                    @change="updateNotif()"
                                />
                                <label>{{ $t('admin.form.notif') }}</label>
                            </div>
                        </div>                        
                        <!-- Archiver -->
                        <!-- Si le suivi n'est pas archiver -->
                        
                        <template v-if="pathologyUpdate.is_archived == 1 && $store.state.USER.infos.role_id === 2">
                            <div class="archive-sep">
                                <h4>{{$t('admin.form.archive')}}</h4>
                                <div class="archive-line"></div>
                            </div>
                            <div class="archive-container">
                                <div class="input-default">
                                    <label>{{$t('admin.form.archiveType')}}</label>
                                    <select v-model="archivedType">
                                        <option disabled class="disabled" value="-1">{{ $t('admin.form.archiveType')}}</option>
                                        <option v-for="(e, idx) in archivedList" :key="idx" :value="e.archived_type_id">{{JSON.parse(e.archived_type_name)[currentLang]}}</option>    
                                    </select>
                                </div>
                                <div class="input-default">
                                    <label>{{$t('admin.form.archiveReason')}}</label>
                                    <textarea
                                        rows="3"
                                        v-model="archive"
                                        maxlength="500"
                                    >
                                    </textarea>
                                </div>
                                <div class="btn" @click="archiveThis()">{{ $t('admin.form.archiveBtn') }}</div>
                            </div>
                        </template>
                        <template v-if="pathologyUpdate.is_archived == 2">
                            <div class="archive-sep">
                                <h4>{{$t('admin.form.archive')}}</h4>
                                <div class="archive-line"></div>
                            </div>
                            <div class="archive-container ">
                                 <div class="input-default archive-reason">
                                    <label>{{$t('admin.form.archiveType')}}</label>
                                    <input type="text" :value="JSON.parse(pathologyUpdate.archived_type_name)[currentLang]" v-if="pathologyUpdate.archived_type_name"/>
                                    <div class="no-archive-type">{{ $t('admin.form.noArchive') }}</div>
                                 </div>
                                <div class="input-default archive-reason">
                                    <label>{{$t('admin.form.archivedReason')}}</label>
                                    <textarea rows="3" :value="pathologyUpdate.archived_reason"></textarea>
                                </div>
                                <div class="btn" @click="activateThis()" v-if="$store.state.USER.infos.role_id === 2">{{ $t('admin.form.activateBtn') }}</div>
                            </div>
                        </template>
                        <!-- btn delete si CDC -->
                        <div class="btn-delete" v-if="$store.state.USER.infos.role_id === 2" @click="deletePathology()">{{$t('admin.form.deletePathology')}}</div>
                </template>
            </div>
        </div>
        <ModalConfirm
            v-if="displayModalConfirm"
            :contentModal="$t('admin.tab.confirmDeletePathology')"
            v-on:close="closeConfirm"
            v-on:confirm="confirmDelete"
        >
        </ModalConfirm>
    </div>
</template>

<script>
import { ValidationProvider } from 'vee-validate';
import axios from 'axios'
import store from './../../store'
import ModalConfirm from './../ModalConfirm'

export default {
    name :'PathologyForm',
    components : {
        ValidationProvider,
        ModalConfirm
    },
    props :['user', 'pathologyUpdate', 'pathologyList', 'archivedList'],
    data() {
        return {
            doctor : -1,
            center : -1,
            stepLeft : null,
            stepRight : null,
            pathologyId : -1,
            weight : 1,
            size : 1,
            notif : false,
            area : null,
            //pathologyList : [],
            role : store.state.USER.role,
            doctorsList : [],
            centersList : [],
            displayModalConfirm :false,
            awaitingTyping :false,
            archive : null,
            archivedType : -1
        }
    },
    computed : {
        imc() {
            if(this.weight && this.size && this.size != 0) {
                const size = Math.pow(this.size / 100 , 2);
                let imc =   (this.weight / size).toFixed(1) 
                this.$emit('changeImc', imc )
                return imc
            }else {
                return null
            }
        },
        currentLang() {
            return this.$store.state.LANG
        }
    },
    mounted() {
        let config = {
             validateStatus: () => true,
        }
        if(store.getters.getToken) {
            config.headers = { Authorization: `Bearer ${store.getters.getToken}` }
        }

        let vm = this

        //Si CDC
        if(store.state.USER.role === 2) {

            this.pathologyCenter = store.state.USER.infos.centers_id
            let centerSelected = {
                centers_id : store.state.USER.infos.centers_id,
                centers_name : store.state.USER.infos.centers_name
            }

            this.centersList.push(centerSelected)
            this.center = store.state.USER.infos.centers_id
            this.$emit('changeCenter', store.state.USER.infos.centers_id)

            axios
                .get(store.getters.getURL +"users", config)
                .then(function (response) {
                    vm.doctorsList = response.data.filter(e => e.role_id === 3)
                })
                .catch((err) => {
                    store.dispatch('addNotif', {type : 2, content : err})
                });
        }else if(store.state.USER.role === 3) {
            let doc = {
                id : store.state.USER.infos.id,
                firstname : store.state.USER.infos.firstname,
                lastname : store.state.USER.infos.lastname
            }
            this.doctorsList.push(doc)
            this.doctor = store.state.USER.infos.id
            this.$emit('changeDoctor', store.state.USER.infos.id)

            axios
                .get(store.getters.getURL +"centers/doctor/" + store.state.USER.infos.id, config)
                .then(function (response) {
                    vm.centersList = response.data
                })
                .catch((err) => {
                    store.dispatch('addNotif', {type : 2, content : err})
                });
        }

        if(this.pathologyUpdate) {
            this.doctor = this.pathologyUpdate.doctor
            this.center = this.pathologyUpdate.center
            this.stepLeft = this.pathologyUpdate.stepLeft
            this.stepRight = this.pathologyUpdate.stepRight
            this.area = this.pathologyUpdate.area
            this.pathologyId = this.pathologyUpdate.pathologyId
            this.weight = this.pathologyUpdate.weight
            this.size = this.pathologyUpdate.size
            this.notif = this.pathologyUpdate.notif
        }
    },
    methods : {
     updateNotif() {

        let config = {
             validateStatus: () => true,
        }
        if(store.getters.getToken) {
            config.headers = { Authorization: `Bearer ${store.getters.getToken}` }
        }

        let params = {
            notif : this.notif,
            id : this.pathologyUpdate.id
        }

        let vm = this

        axios
            .put(store.getters.getURL +"monitoring/follow", params, config)
            .then(function (response) {
                if(response.status === 200) {
                    store.dispatch('addNotif', {type : 1, content : 14})
                    vm.$emit('updateNotif', vm.user.id, params)
                }
            })
            .catch((err) => {
                store.dispatch('addNotif', {type : 2, content : err})
            });

        },
        updateStade(){
            if(this.pathologyUpdate) {
                let config = {
                    validateStatus: () => true,
                }
                if(store.getters.getToken) {
                    config.headers = { Authorization: `Bearer ${store.getters.getToken}` }
                }

                let params = {
                    stepLeft : Number(this.stepLeft),
                    stepRight : Number(this.stepRight),
                    id : this.pathologyUpdate.id
                }

                let vm = this

                axios
                    .put(store.getters.getURL +"monitoring/stade", params, config)
                    .then(function (response) {
                        if(response.status === 200) {
                            store.dispatch('addNotif', {type : 1, content : 16})
                            vm.$emit('updateStade', vm.user.id, params)
                        }
                    })
                    .catch((err) => {
                        store.dispatch('addNotif', {type : 2, content : err})
                    });
            }else { 
                let params= {
                    stepLeft : Number(this.stepLeft),
                    stepRight : Number(this.stepRight),
                }
                this.$emit('changeStep', params)
            }
 
            
        },
        deletePathology(){
            this.displayModalConfirm = true
        },
        closeConfirm() {
            this.displayModalConfirm = false
        },
        confirmDelete() {
            this.displayModalConfirm = false
            let config = {
                validateStatus: () => true,
            }
            if(store.getters.getToken) {
                config.headers = { Authorization: `Bearer ${store.getters.getToken}` }
            }

            let vm = this

            axios
                .delete(store.getters.getURL +"monitoring/"+ this.pathologyUpdate.id, config)
                .then(function (response) {
                    if(response.data.success) {
                        vm.$emit('deletePathology')
                        store.dispatch('addNotif', {type : 1, content : 15})  
                    }else {
                        store.dispatch('addNotif', {type : 2, content : JSON.parse(response.data.message)[store.state.LANG]})
                    }
                })
                .catch((err) => {
                    store.dispatch('addNotif', {type : 2, content : err})
                });
           
        },
        updateStep() {
            let step = {
                stepLeft : this.stepLeft,
                stepRight : this.stepRight
            }
            this.$emit('changeStep',  { step } )
        },
        changePathology() {
            this.step = null
            this.area = null
            this.$emit('changePathology', this.pathologyId )
        },
        changeArea() {
            if(this.pathologyUpdate) {
                let config = {
                    validateStatus: () => true,
                }
                if(store.getters.getToken) {
                    config.headers = { Authorization: `Bearer ${store.getters.getToken}` }
                }

                let params = {
                    area : this.area,
                    id : this.pathologyUpdate.id
                }

                let vm = this

                axios
                    .put(store.getters.getURL +"monitoring/area", params, config)
                    .then(function (response) {
                        if(response.status === 200) {
                            store.dispatch('addNotif', {type : 1, content : 25})
                            vm.$emit('updateArea')
                        }
                    })
                    .catch((err) => {
                        store.dispatch('addNotif', {type : 2, content : err})
                    });
            }else {
                this.$emit('addArea', this.area )
            }
        },
        changeWeightOrSize() {
            
            if(this.pathologyUpdate) {
                if (!this.awaitingTyping) {
                    setTimeout(() => {
                        let config = {
                            validateStatus: () => true,
                        }
                        if(store.getters.getToken) {
                            config.headers = { Authorization: `Bearer ${store.getters.getToken}` }
                        }

                        let params = {
                            size : this.size,
                            weight : this.weight,
                            imc : this.imc,
                            id : this.pathologyUpdate.id
                        }

                        let vm = this

                        axios
                            .put(store.getters.getURL +"monitoring/weight_or_size", params, config)
                            .then(function (response) {
                                if(response.status === 200) {
                                    vm.$emit('changeWeightOrSize')
                                }else {
                                    store.dispatch('addNotif', {type : 2, content : JSON.parse(response.data.message)[store.state.LANG] })
                                }
                            })
                            .catch((err) => {
                                store.dispatch('addNotif', {type : 2, content : err})
                            });
                    
                        this.awaitingTyping = false;
                    }, 1000);
                }
                this.awaitingTyping = true;
            }else {
                this.$emit('changeSize', this.size)
                this.$emit('changeWeight', this.weight)
            }
        },
        archiveThis() {
            if(this.pathologyUpdate) {           
                let config = {
                    validateStatus: () => true,
                }
                if(store.getters.getToken) {
                    config.headers = { Authorization: `Bearer ${store.getters.getToken}` }
                }

                let params = {                    
                    id : this.pathologyUpdate.id,
                    archive : this.archive,
                    type : this.archivedType
                }

                let vm = this

                axios
                    .put(store.getters.getURL +"monitoring/archive", params, config)
                    .then(function (response) {
                        if(response.status === 200) {
                            store.dispatch('addNotif', {type : 1, content : 28})
                            vm.$emit('archivePathology')
                        }else {
                            store.dispatch('addNotif', {type : 2, content : JSON.parse(response.data.message)[store.state.LANG]})
                        }
                    })
                    .catch((err) => {
                        store.dispatch('addNotif', {type : 2, content : err})
                    });
        }
            
        },
        activateThis() {
            if(this.pathologyUpdate) {
                let config = {
                    validateStatus: () => true,
                }
                if(store.getters.getToken) {
                    config.headers = { Authorization: `Bearer ${store.getters.getToken}` }
                }

                let params = {                    
                    id : this.pathologyUpdate.id
                }

                let vm = this

                axios
                    .put(store.getters.getURL +"monitoring/activate", params, config)
                    .then(function (response) {
                        if(response.status === 200) {
                            store.dispatch('addNotif', {type : 1, content : 27})
                            vm.$emit('activatePathology')
                        }else {
                            store.dispatch('addNotif', {type : 2, content : JSON.parse(response.data.message)[store.state.LANG]})
                        }
                    })
                    .catch((err) => {
                        store.dispatch('addNotif', {type : 2, content : err})
                    });
            }
        },
    }

}
</script>

<style lang="scss" scoped>
    .pathology-form {
        width:100%;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .pathology-form .input-default input[type="checkbox"] {
        width:18px;
        height:18px;
        border-radius:2px;
        border:1px solid $mainColor;
        background: #fff;
        margin-right:20px;
        cursor: pointer;
        padding:0;
    }

    .pathology-form .input-default input[type="checkbox"]:checked {
        background: $mainColor;
    } 

    .ckeckbox-container {
        display: flex;
        align-items: center;
    }

    .pathologie-sep {
        height:20px;
        margin:20px auto;
        width:80%;
        display: flex;
        justify-content: center;
        position:relative
    }

    .pathologie-sep h3 {
        color:$mainColor;
        font-size:18px;
        text-transform: uppercase;
        font-family: $font;
        background:#fff;
        text-align: center;
        display: inline-block;
        position: relative;
        z-index: 99;
        padding:0 20px;
    }

    .pathologie-line {
        height:1px;
        width: 100%;
        position:absolute;
        top:50%;
        background:$mainColor;
    }

    .btn-delete {
        margin: 20px 0;
        display: flex;
        justify-content: center;
        cursor: pointer;
    }

    .btn-delete:hover {
        text-decoration: underline;
    }

    .label-pathology {
        margin-left:5px;
    }

    .archive-sep {
        height:20px;
        margin:20px auto;
        width:80%;
        display: flex;
        justify-content: center;
        align-items: center;
        position:relative
    }

    .archive-sep h4 {
        color:$textGrey;
        font-size:15px;
        text-transform: uppercase;
        font-family: $font;
        background:#fff;
        text-align: center;
        display: inline-block;
        position: relative;
        z-index: 99;
        padding:0 20px;
    }

    .archive-line {
        height:1px;
        width: 100%;
        position:absolute;
        top:50%;
        background:$textGrey;
    }

    .archive-container {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .archive-container .input-default {
        margin:10px 0;
    }

    .archive-container .input-default input {
        margin-right:20px;
        width:calc(100% - 60px);
    }

    .archive-container .btn {
        padding-right:20px;
        padding-left:20px;
    }

    .archive-reason textarea, .archive-reason input {
        pointer-events: none;
        opacity: 0.5;
    }

    .no-archive-type {
        padding:10px;
        color:$textGrey;
        margin-top:10px;
    }
</style>