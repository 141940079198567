<template>
    <div class="form-add">
        <Loader v-if="isLoading"></Loader>
        <template v-else>
        <h2>{{ $t('admin.form.addCoordinatorTitle')}}</h2>
        <ValidationObserver v-slot="{ handleSubmit }">
          <form @submit.prevent="handleSubmit(submit)">
           <div class="row">
                <div class="col50">
                        <ValidationProvider  class="input-default"  rules="required|max:100" v-slot="{ errors }">
                            <input type="text" :class="{ 'input-error' : errors[0]}"  v-model="firstname" :placeholder=" $t('admin.form.firstname')" />
                            <span class="notice-error">{{ errors[0] }}</span>
                        </ValidationProvider>
                    </div>
                    <div class="col50">
                        <ValidationProvider  class="input-default"  rules="required|max:100"  v-slot="{ errors }">
                            <input type="text" :class="{ 'input-error' : errors[0]}"  v-model="lastname" :placeholder=" $t('admin.form.lastname')" />
                            <span class="notice-error">{{ errors[0] }}</span>
                        </ValidationProvider>
                    </div>
                </div>
                <div class="row">
                    <div class="col50">
                        <ValidationProvider  class="input-default"  rules="required|max:100|email" vid="email" v-slot="{ errors }">
                            <input type="text" :class="{ 'input-error' : errors[0]}"  v-model="email" :placeholder=" $t('admin.form.email')" />
                            <span class="notice-error">{{ errors[0] }}</span>
                        </ValidationProvider>
                    </div>
                    <div class="col50">
                        <ValidationProvider  class="input-default"  rules="required|max:100|email|confirmed:email"  v-slot="{ errors }">
                            <input type="text" :class="{ 'input-error' : errors[0]}"  v-model="emailConfirm" :placeholder=" $t('admin.form.emailConfirm')" />
                            <span class="notice-error">{{ errors[0] }}</span>
                        </ValidationProvider>
                    </div>
                </div>
                <div class="row">   
                    <div class="col50">
                        <ValidationProvider  class="input-default"  rules="required" v-slot="{ errors }">
                            <select v-model="centerSelected" :class="{ 'input-error' : errors[0]}">
                                <option disabled class="disabled">{{ $t('admin.form.center')}}</option>
                                <option v-for="(item, idx) in centers " :key="idx" :value="item.centers_id">{{ item.centers_name}}</option>
                            </select>   
                            <span class="notice-error">{{ errors[0] }}</span>
                        </ValidationProvider>
                    </div>
                </div>                
                <input type="submit" class="input-submit" :value="$t('admin.form.addBtn')" />                
          </form>
        </ValidationObserver>
        </template>
    </div>
</template>

<script>
import { ValidationProvider,  ValidationObserver } from 'vee-validate';
import axios from 'axios'
import store from './../../store'
import Loader from './../Loader'

export default {
    name : 'AddCoordinator',
    components : {
      ValidationProvider,
      ValidationObserver,
      Loader
    },
    props : ['centers'],
    data() {
        return {
            firstname : null,
            lastname : null,
            email :null,
            emailConfirm : null,
            centerSelected : null,
            isLoading : false
        }
    },
    methods : {
        submit() {

            this.isLoading = true
        
            let params = {
                firstname : this.firstname,
                lastname : this.lastname,
                email :this.email,
                emailConfirm : this.emailConfirm,
                center : this.centerSelected,
                role_id : 2
            }

            let config = {
                validateStatus: () => true,
            }
            if(store.getters.getToken) {
                config.headers = { Authorization: `Bearer ${store.getters.getToken}` }
            }

            let vm = this

            axios
                .post(store.getters.getURL +"users", params, config)
                .then(function (response) {
                    vm.isLoading = false
                    if(response.status === 200) {
                        let user = {
                            id : response.data.id,
                            firstname : params.firstname,
                            lastname : params.lastname,
                            email : params.email,
                            role_id : params.role_id,
                            centers : [response.data.centers]
                        }
                        vm.$emit('addCoordinator', user)
                    }else {
                        store.dispatch('addNotif', {type : 2, content : JSON.parse(response.data.message)[store.state.LANG]})
                    }
                    
                })
                .catch((err) => {
                    store.dispatch('addNotif', {type : 2, content : err})
                });
            
            
        }
    }
}
</script>