<template>
    <div :class="['modal',  isSafari() ]" @click="closeModal">
        <div class="modal-close" @click="closeModal"></div>
        <div class="modal-container" @click.stop>
            <div class="add-switcher" v-if="displaySwitch">
                <select v-model="addSwitch">
                    <option class="disabled" value="-1">{{$t('admin.form.addChoice')}}</option>
                    <option v-if="selectChoice.find(e => e == 1)" value="1">{{$t('admin.form.addPatient')}}</option>
                    <option v-if="selectChoice.find(e => e == 2)" value="2">{{$t('admin.form.addDoctor')}}</option>
                    <option v-if="selectChoice.find(e => e == 3)" value="3">{{$t('admin.form.addCoordinator')}}</option>
                </select>
            </div>
            <AddPatient v-if="addSwitch == 1" v-on:addPatient="addPatient"></AddPatient>
            <AddDoctor v-if="addSwitch == 2" :centers="centers" v-on:addDoctor="addDoctor"></AddDoctor>
            <AddCoordinator v-if="addSwitch == 3" :centers="centers" v-on:addCoordinator="addCoordinator"></AddCoordinator>
        </div>
    </div>
</template>

<script>
import AddCoordinator from './forms/AddCoordinator'
import AddDoctor from './forms/AddDoctor'
import AddPatient from './forms/AddPatient'
import axios from 'axios'
import store from './../store'

export default {
    name : 'ModalAddUser',
    props : ['selectChoice', 'selectDefault', 'displaySwitch'],
    components : {
        AddDoctor,
        AddCoordinator,
        AddPatient
    },
    data() {
        return {
            addSwitch  : null,
            centers : null
        }
    },
    methods : {
        closeModal() {
            this.$emit('close')
        },
        addDoctor(user) {
            this.$emit('addDoctor', user)
        },
        addCoordinator(user) {
            this.$emit('addCoordinator', user)
        },
        addPatient(user) {
            this.$emit('addPatient', user)
        },
        isSafari() {
            if(/apple/i.test(navigator.vendor)) {
                return 'is-safari'
            }else {
                return 'is-not'
            }

        }
    },
    mounted() {
        this.addSwitch = this.selectDefault
        
        //récupération des centres
        let config = {
             validateStatus: () => true,
        }
        if(store.getters.getToken) {
            config.headers = { Authorization: `Bearer ${store.getters.getToken}` }
        }

        let vm = this

        axios
            .get(store.getters.getURL +"centers", config)
            .then(function (response) {
                vm.centers = response.data               
            })
            .catch((err) => {
                store.dispatch('addNotif', {type : 2, content : err})
            });
    }
}
</script>

<style lang="scss" scoped>

    .add-switcher {
        margin : 40px auto 0 auto;
        display: flex;
        justify-content: center;
    }

    select {
        width:400px;
        padding:10px 20px;
        background:$inputBackground;
        border-radius:10px;
        font-size:15px;
        color:$textColor; 
        font-family: $font;
        border:1px solid transparent;
    }

    select:focus {
        outline:none;
        border:1px solid $mainColor;
    }

    .modal-container {
        max-height: 90%;
        max-width:1000px;
        width:90%;
    }
</style>