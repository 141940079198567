<template>
    <div class="export-container">
        <div class="btn" @click="exportPatient">{{ $t('export.btn')}}</div>
        <div class="export-anonym-container" v-if="this.$store.state.USER.role != 1">                    
            <input v-model="anonym" class="" type="checkbox" />
            <label>{{ anonym ?  $t('export.anonym') : $t('export.notAnonym') }}</label>
        </div>                
    </div>
</template>

<script>
export default {
    name: 'Export',
    data() {
        return {
            anonym : true
        }
    },
    methods : {
        exportPatient() {    
            let mode = 2;
            if (this.anonym) {
                mode= 1;
            }
            window.open(this.$store.getters.getURL+'export/' + this.$store.getters.getToken + '/' + mode, '_blank')
        }
    }
}
</script>

<style lang="scss" scoped>
    .export-container {
        display: flex;
        align-items: center;
    }

    .export-anonym-container {
        display:flex;
        align-items: center;
        margin-left: 20px;
    }

    .export-container .btn {
        font-family: "Conv_GothamBook";
        font-size:15px;
        height:auto;
        padding:10px;
        text-transform: uppercase;
    }

    input[type="checkbox"] {
        width:18px;
        min-width:18px;
        height:18px;
        border-radius:2px;
        border:1px solid $mainColor;
        background: #fff;
        margin-right:10px;
        cursor: pointer;
        padding:0;
    }

    
    input[type="checkbox"]:checked {
        background: $mainColor;
    } 

</style>