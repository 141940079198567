<template>
    <div class="form-add">
        <Loader v-if="isLoading"></Loader>
        <template v-else>
        <h2>{{ $t('admin.form.addPatientTitle')}}</h2>                
            <ValidationObserver v-slot="{ handleSubmit }" ref="form">
          <form @submit.prevent="handleSubmit(submit)">
           <div class="row">
                <div class="col50">
                        <ValidationProvider  class="input-default"  rules="required|max:50" v-slot="{ errors }">
                            <input type="text" :class="{ 'input-error' : errors[0]}"  v-model="firstname" name="firstname" :placeholder=" $t('admin.form.firstname')" />
                            <span class="notice-error">{{ errors[0] }}</span>
                        </ValidationProvider>
                    </div>
                    <div class="col50">
                        <ValidationProvider  class="input-default"  rules="required|max:50"  v-slot="{ errors }">
                            <input type="text" :class="{ 'input-error' : errors[0]}"  v-model="lastname" name="lastname" :placeholder=" $t('admin.form.lastname')" />
                            <span class="notice-error">{{ errors[0] }}</span>
                        </ValidationProvider>
                    </div>
                </div>
                <div class="row">
                    <div class="col50">
                        <ValidationProvider  class="input-default"  rules="required|max:50|email" vid="email" v-slot="{ errors }">
                            <input type="text" :class="{ 'input-error' : errors[0]}"  v-model="email" name="email" :placeholder=" $t('admin.form.email')" />
                            <span class="notice-error">{{ errors[0] }}</span>
                        </ValidationProvider>
                    </div>
                    <div class="col50">
                        <ValidationProvider  class="input-default"  rules="required|max:50|email|confirmed:email"  v-slot="{ errors }">
                            <input type="text" :class="{ 'input-error' : errors[0]}"  v-model="emailConfirm" name="emailConfirm" :placeholder=" $t('admin.form.emailConfirm')" />
                            <span class="notice-error">{{ errors[0] }}</span>
                        </ValidationProvider>
                    </div>
                </div>
                <div class="row">
                    <div class="col50">
                        <ValidationProvider  class="input-default"   rules="required" v-slot="{ errors }">
                            <input type="date" :class="{ 'input-error' : errors[0]}"  v-model="birthday" name="birthday" :placeholder=" $t('patient.form.birthday')" />
                            <span class="notice-error">{{ errors[0] }}</span>
                        </ValidationProvider>
                    </div>
                    <div class="col50">
                        <ValidationProvider  class="input-default"  rules="required" v-slot="{ errors }">
                            <select v-model="sex" :class="{ 'input-error' : errors[0]}" name="sex">
                                <option value="-1" disabled>{{ $t('patient.form.sex') }}</option>
                                <option value="2">Femme</option> 
                                <option value="1">Homme</option>
                            </select>
                            <span class="notice-error">{{ errors[0] }}</span>
                        </ValidationProvider>
                    </div>
                    <!-- <div class="col33">
                        <ValidationProvider  class="input-default"  rules="required" v-slot="{ errors }">
                            <select v-model="marital_status" :class="{ 'input-error' : errors[0]}" name="marital_status">
                                <option value="-1" disabled>{{ $t('patient.form.situation') }}</option>
                                <option v-for="(item, idx) in statusList" :key="idx" :value="item.marital_status_id">{{ JSON.parse(item.marital_status_name)[currentLang]}}</option> 
                            </select>
                            <span class="notice-error">{{ errors[0] }}</span>
                        </ValidationProvider>
                    </div> -->
                </div>  
                <div class="row">
                    <div class="col50">
                        <ValidationProvider  class="input-default"   rules="max:25" v-slot="{ errors }">
                            <input type="text" :class="{ 'input-error' : errors[0]}"  v-model="phone" name="phone" :placeholder=" $t('patient.form.phone')" />
                            <span class="notice-error">{{ errors[0] }}</span>
                        </ValidationProvider>
                    </div>
                </div>    

                <PathologyForm 
                    v-on:changeDoctor="changeDoctor"
                    v-on:changeCenter="changeCenter"
                    v-on:changePathology="changePathology"
                    v-on:changeStep="changeStep"
                    v-on:changeSize="changeSize"
                    v-on:changeWeight="changeWeight"
                    v-on:changeImc="changeImc"
                    v-on:changeNotif="changeNotif"
                    :pathologyList="pathologyList"
                    v-on:changeArea="changeArea"
                    v-on:addArea="addArea"
                >
                </PathologyForm>


                <input type="submit" class="input-submit" :value="$t('admin.form.addBtn')" />                
          </form>
        </ValidationObserver>
        </template>
    </div>
</template>

<script>
import { ValidationProvider,  ValidationObserver } from 'vee-validate';
import PathologyForm from './../forms/PathologyForm'
import store from './../../store'
import axios from 'axios'
import Loader from './../Loader'

export default {
    name : 'AddPatient',
    components : {
      ValidationProvider,
      ValidationObserver,
      Loader,
      PathologyForm
    },
    data() {
        return {
            firstname : null,
            lastname : null,
            email : null,
            emailConfirm : null,
            phone : null,
            birthday : null,
            marital_status : -1,
            sex : -1,
            counter : 1,
            statusList : [],
            isLoading : false,
            pathology : {
                notif:false,
                step :null,
                imc:null,
                size:1,
                weight:1,
                doctor:-1,
                center:-1,
                pathologyId:-1,
                area : null
            },
            pathologyList : []
        }
    },
    methods: {
        submit() {

            this.isLoading = true
        
            let params = {
                firstname : this.firstname,
                lastname : this.lastname,
                email :this.email,
                emailConfirm : this.emailConfirm,
                birthday : this.birthday,
                // marital_status : this.marital_status,
                sex : this.sex,
                phone : this.phone,
                role_id : 4,
                pathology :this.pathology
            }


            let config = {
                validateStatus: () => true,
            }
            if(store.getters.getToken) {
                config.headers = { Authorization: `Bearer ${store.getters.getToken}` }
            }

            let vm = this

            axios
                .post(store.getters.getURL +"users", params, config)
                .then(function (response) {
                    vm.isLoading = false
                    if(response.status === 200) {
                        let user = {
                            id : response.data.id,
                            firstname : params.firstname,
                            lastname : params.lastname,
                            email : params.email,
                            role_id : params.role_id,
                            // marital_status : params.marital_status,
                            birthday : params.birthday,
                            sex : params.sex,
                            phone : params.phone,
                            pathology : response.data.pathology,
                            center_filter : true
                        }
                        vm.$emit('addPatient', user)
                    }else {
                        store.dispatch('addNotif', {type : 2, content : JSON.parse(response.data.message)[store.state.LANG]})
                    }
                    
                })
                .catch((err) => {
                    store.dispatch('addNotif', {type : 2, content : err})
                });
            
            
            
        },
        removePathology(id) {   
            this.pathology.splice(this.pathology.findIndex(el => el.id === id) ,1)
        },
        addPathology() {
            this.counter++;
            let newPathology = {
                id : this.counter,
                doctor : -1,
                center : -1,
                step : null,
                pathologyId : -1,
                weight : 1,
                size : 1,
                notif : false,
                imc : null,
                area : null
            }
            this.pathology.push(newPathology)
        },
        changeDoctor(doctor) {
            this.pathology.doctor = doctor
        },
        changeCenter(center) {
            this.pathology.center = center
        },
        changePathology(pathology) {
            this.pathology.pathologyId = pathology
        },
        changeStep(step) {
            this.pathology.stepLeft = step.stepLeft
            this.pathology.stepRight = step.stepRight
        },
        changeSize(size) {
            this.pathology.size = size
        },
        changeWeight(weight) {
            this.pathology.weight = weight
        },
        changeImc(imc) {
            this.pathology.imc = imc
        },
        changeNotif(notif) {
            this.pathology.notif = notif
        },        
        changeArea(area) {
            this.pathology.area = area
        },
        addArea(area) {
            this.pathology.area = area
        },
    },
    mounted() {
        let config = {
             validateStatus: () => true,
        }
        if(store.getters.getToken) {
            config.headers = { Authorization: `Bearer ${store.getters.getToken}` }
        }

        let vm = this

        axios
            .get(store.getters.getURL +"marital_status", config)
            .then(function (response) {
                vm.statusList = response.data
            })
            .catch((err) => {
                store.dispatch('addNotif', {type : 2, content : err})
            });

        axios
            .get(store.getters.getURL +"pathology", config)
            .then(function (response) {
                vm.pathologyList = response.data
            })
            .catch((err) => {
                store.dispatch('addNotif', {type : 2, content : err})
            });
    },
    computed : {
        currentLang() {
            return this.$store.state.LANG
        }
    }
}
</script>

<style lang="scss" scoped>

    .pathologie-line {
        height:1px;
        width: 100%;
        position:absolute;
        top:50%;
        background:$mainColor;
    }

    .pathologie-add-container{
        height:120px;
        margin:20px auto;
        width:80%;
        display: flex;
        justify-content: center;
        position:relative
    }

    .pathologie-add-container .pathologie-line {
        top:30px;
    }

    .pathologie-add-content {
        display:flex;
        flex-direction: column;
        align-items: center;
        background:#fff;
        position: relative;
        z-index: 99;
    }

    .pathologie-add-btn {
        height:60px;
        width:60px;
        background:$inputBackgroundLogin;
        color:#fff;
        border-radius: 50%;
        font-size:40px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        transition: 0.5s;
    }

    .pathologie-add-btn:hover {
        background: $mainColor;
    }

    .pathologie-add-text {
        text-align: center;
        font-size:12px;
        color:$textColor;
        font-family: $font;
        line-height: 15px;
        margin-top:5px;
    }
</style>